.wrapper {
  position: fixed;
  z-index: 1200;
  display: none;
  overflow: hidden auto;
  inset: 0;
  align-items: center;
  justify-content: center;

  &_open {
    display: flex;
  }
}

.backdrop {
  position: fixed;
  background-color: var(--uk-kit-2-colors-backgroundSeventh);
  opacity: 0.3;
  inset: 0;
}

.window {
  position: absolute;
  height: auto;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;

  //top: 10px;
  background-color: var(--uk-kit-2-colors-backgroundPrimary);
  overflow-y: auto;
  border-radius: 8px;
  box-shadow:
    0 12px 29px rgb(129 105 138 / 6%),
    0 2.6804px 6.4775px rgb(129 105 138 / 3.58%),
    0 0.798px 1.9285px rgb(129 105 138 / 2.42%);

  @media screen and (width <= 600px) {
    width: auto;
    margin: 10px;
    padding: 30px;
  }
}
