.common {
  &_margin {
    &__xxs {
      margin-top: 5px;
    }

    &__xs {
      margin-top: 10px;
    }

    &__s {
      margin-top: 15px;
    }

    &__m {
      margin-top: 20px;
    }

    &__l {
      margin-top: 30px;
    }

    &__xl {
      margin-top: 35px;
    }

    &__xxl {
      margin-top: 40px;
    }
  }

  &_buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    @media all and (width <= 768px) {
      gap: 10px;
    }

    &__narrow {
      justify-content: flex-start;
    }
  }
}
