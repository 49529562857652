.page {
  &_block {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__border {
      padding: 30px;
      border: 1px solid var(--uk-kit-2-colors-interfaceFifth);
      border-radius: 8px;

      @media all and (width <= 375px) {
        padding: 20px;
      }
    }

    &__row {
      flex-direction: row;
    }
  }
}
