@use "../../assets/styles/functions";

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: functions.cssVar(size, headerHeight);
  padding: 17px 40px;
  background-color: var(--uk-kit-2-colors-backgroundSecondary);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--uk-kit-2-colors-backgroundTertiary);
}
