@use "../../assets/styles/functions";
@use "../../assets/styles/common.module";

.root {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: functions.cssVar(size, headerHeight);
}

.content {
  width: inherit;
  max-width: 870px;
}
