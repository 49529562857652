@use "mixins";
@use "sass:map";

$variables: (
  "size": (
    "headerHeight": 80px),
    "customize": ("headerBackground": "linear-gradient(90.07deg, rgba(253, 252, 255, 0.364225) 0.03%, rgba(255, 255, 255, 0.22) 3.17%, #F6F1FF 26.7%, rgba(255, 255, 255, 0.56) 50.23%, rgba(240, 244, 255, 0.6) 72.72%, rgba(253, 252, 255, 0.83) 95.2%), #FFFFFF"
  )
);

:root {
  @include mixins.generateCssVars($variables);
}
